/* Default layout */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}


.mimg{
  max-width: 100%;
  width: 100%;
}
/* Mobile layout */
@media (max-width: 768px) {
 
  .second-section {
    grid-row: 1;
  }

  .tcard {
    grid-row: 2;
    
  }
}

.cross-img {
  position: absolute;
  top: 2px;
  right: 10px;
  cursor: pointer;
}


.padding {
  padding-left: 16%;
  padding-right: 16%;
}
/* @media only screen and (max-width: 768px) {
  #responsive-img {
    content: url('assets/mobile.svg');
  }
} */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px; /* Adjust as needed */
  padding-left: 16%;
  padding-right: 0%;
}

.navbar-logo {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  height: 50px;
}
@keyframes zoomText {
  0%,
  100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.2);
  }
}

@keyframes backgroundColorChange {
  0%,
  100% {
      background-color: rgba(36, 56, 144, 1);
  }
  50% {
      background-color: rgba(24, 38, 108, 1);
  }
}

.book-button {
  background-color: #036cde;
  color: white;
  border: none;
  padding: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 9px;
  height: 35px;
  width: 140px;
  position: relative;
  overflow: hidden;
  animation: backgroundColorChange 2s infinite;
  font-size: 13px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.book-image {
  width: 50px; /* Adjust size as needed */
  height: auto;
}

.overlay-gif {
  position: absolute;
  top: 13px;
  right: 10px;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
}

.book-button span {
  display: inline-block;
  animation: zoomText 2s infinite;
}
.header-content p{
  font-size: 14px;
}



.header-bar {
  background-color: RGB(254, 191, 49, 1);
  padding: 10px;
  color: black;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-bar p {
  margin: 0; /* Remove default margin for paragraphs */
}

.card {
  border: 0px solid #ddd;
  border-radius: 3px;
  overflow: hidden;
  width: 300px;
  font-family: Arial, sans-serif;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  position: fixed;
  background-color: #fffdfd;
  margin-top: -30%;
  margin-left: 63%;
  transition: margin-top 0.3s ease-in-out;
}

.card-video {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.card-video iframe {
  width: 100%;
  height: 250px;
  display: block;
}

.card-content {
  padding: 10px;
  margin-top: 0%;
}

.card-content p{
  font-size: 15px;

}

.limited-time-offer {
  padding-top: 0%;
  margin-top: 1px;
  display: flex;
  align-items: center;
}

.limited-time-offer .icon {
  margin-right: 10px;
}

.offer{
  font-size: 15px;
}

.enroll-button {
  margin-top: 2%;
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #036cde;
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  animation: backgroundColorChange 2s infinite;
}

.enroll-button:hover {
  background-color: #0056b3;
}

.enroll-button span {
  display: inline-block;
  animation: zoomText 2s infinite;
}

.card-includes{
  margin-top: 6%;
  margin-bottom: 1px;
}



.wide {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-top: 0%;
  padding-top: 0%;
}

.youtube {
  width: 100%;
  height: 250px;
}
@media (max-width: 790px) {
  .navbar-logo{
    width: 150px;
    margin-bottom: 0%;
    padding-bottom: 0%;
    margin-left: 1%;
  }
  .image-container{
    margin-bottom: 0%;
    padding-bottom: 0%;
  }
  .card{
    border: 0px;
  }


  .navbar{
    padding-top: 0%;
    padding-bottom: 0%;
    margin-bottom: 0%;
  }

  
.book-button {margin: 0%;
padding: 0%;
font-size: 14px;
}

  .header-content{
    text-align: center;
    font-size: 14px;
  }
  .card-video{
    width: 100%;
    height: 100%;
  }
  .wide {
    width: 100%;
    margin-bottom: 0%;
    padding-bottom: 0%;
    margin-top: 0%;
  }
  .youtube{
    width: 100%;
    height: auto;
  }
  .padding{
    padding-left: 0%;
    padding-right: 0%;
  }
  .second-section {
    order: 1;
  }
  .tcard{
    order: 2;
  }

  .card {
    
    position: static; /* Remove fixed position on mobile */
    margin-left: 100px;
    margin-top: 70%;
  }

  .card {
    width: 100%; /* Adjust card width for mobile */
    margin: 10px auto;
  }
  .card-includes{
    margin-top: 4%;
  }

  #responsive-img{
    width: 100%;
  }
}


.learn {
  border: 1px solid #ddd;
  padding: 20px;
  margin-top: 6%;
  background-color: #fff;
  max-width: 60%;
}

.learn h2 {
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 30px;
  padding-top: 4%;
}

.learn-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #333;
  font-size: 14px;
}

.learn-item:last-child {
  margin-bottom: 0;
}

.arrow {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-left: 4px; /* Ensure equal gap */
  flex-shrink: 0; /* Prevent shrinking */
}

.arrow img {
  width: 100%;
  height: 100%;
}


.clients{
   width: 65%;
   margin-top: 0%;
}
.swiper-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.swiper-wrapper {
  display: flex;
  margin-bottom: 0%;
}

.swiper-slide {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
}

.eh3 {
  font-size: 30px;
}

@media (max-width: 980px) {
  .eh3 {
      font-size: 90px;
  }

  .now {
      margin-top: 200px;
      height: 170px;
      width: 490px;
      font-size: 3rem;
  }

  .swiper-slide {
      flex: 0 0 100%;
      max-width: 100%;
  }

  .swiper-slide img {
      height: 300px; /* Increased height for mobile */
  }
}

.tests {
  font-size: 30px;
}

@media only screen and (max-width: 980px) {
  .swiper-container {
      width: 100%;
      height: 300px;
      overflow: hidden;
      position: relative;
  }

  .swiper-slide {
      height: 300px;
  }
}




@media (max-width: 780px) {

  .learn {
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 5%;
    background-color: #fff;
    max-width: 100%;
  }

  .clients{
    width: 100%;
    margin-top: 5%;
    margin-left: 0%;
    height: auto;
 }
 

}


.heading {
  font-size: 30px;
 font-weight: 600;
  margin-bottom: 10px;
  margin-top: 7%;
}



.chapterst {
  max-width: 65%;
}

.chaptert {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc; /* Adds underline */
  padding-bottom: 10px; /* Adds space between the content and underline */
}

.chaptert-title {
  font-weight: 508;
  cursor: pointer; /* Indicates that the title is clickable */
  color: #000000;
  font-size: 15px;
  transition: color 0.3s ease;
}

.chaptert-title:hover {
  color: #0056b3; /* Change color on hover */
}

.chaptert-content {
  text-align: justify;
  margin-left: 20px; /* Adds indentation for better readability */
  font-size: 14px;
}

.show-more-button {
  display: block;
  margin-left: 25%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #075ebc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  margin-bottom: 5%;
}

.show-more-button:hover {
  background-color: #0056b3;
}
.hcl{
  font-size: 30px;
  margin-top: 4%;
  font-weight: 600;
}

.course-includes {

  padding-left: 0%;
  background-color: #fff;
  max-width: 65%;
  margin: 0px;
  padding-top: 5%;
  
}

.course-includes h2 {
  color: #333;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 30px;
  font-weight: 600; /* Adjust the font size as needed */
}

.course-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.course-item {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  color: #333;
  font-size: 14px; /* Decreased font size */
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  flex-shrink: 0;
}

.icon img {
  width: 100%;
  height: 100%;
}

@media (max-width: 780px) {

  .course-items {
    grid-template-columns: 1fr; /* One column layout */
}
  .hcl{
    margin-top: 0%;
  }


.show-more-button { 
  margin-left: 0%;
}
  
.chapterst{
  max-width: 100%;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
}

  .course-includes {

    padding: 20px;
    background-color: #fff;
    max-width: 100%;
    margin: 0px;
    
  }





}


.featured-reviews {
  padding-top: 0%;
  border: 0px solid #ddd;
  padding: 0px;
  background-color: #fff;
  max-width: 65%;
  margin: 0px;
  margin-top: 5%;
}

.featured-reviews h2 {
  color: #333;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
}

.reviews {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.review {
 border-top: 1px solid #ddd;
 border-bottom: 1px solid #ddd;
  padding: 20px;
  background-color: #fff;
  width: 48%;
  margin-bottom: 10px; /* Added margin for spacing between reviews */
}

.profile {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 0%;
  margin-right: 10px;
}

.name {
  font-weight: bold;
  margin-bottom: 0%;
  margin-top: 0%;
  font-size: 17px;
}

.course-name, .reviews-count {
  color: #666;
  margin-top: 2px;
  margin-bottom: 2px;
}

.stars {
  display: flex;
  margin-bottom: 2px;
  width: 200px;
}

.star-img {
  width: 90px;
  height: auto;
  margin-right: 0px;
}

.review-text {
  color: #333;
  margin-bottom: 10px;
  font-size: 13px;
}

.helpful {
  display: flex;
  align-items: center;
}

.helpful p {
  margin-right: 10px;
}

@media (max-width: 780px) {
  .featured-reviews {
    max-width: 100%;
  }

  .review {
    width: 100%;
  }

  .review-text {
    font-size: 13px;
  }

  .reviews {
    flex-direction: column;
  }

  .review:not(:first-child) {
    display: none;
  }
}




.footer{
  background-image: url('./assets/Frame\ 76\ \(1\).svg');
}
.container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  padding-left: 0%;
  max-width: 68%;
  margin: 0%;
}

/* 
.container {
  display: flex;
  flex-direction: row;
  padding: 20px;
} */
.level-tabs-container {
  position: relative;
  margin-right: 20px;
  display: flex;
  align-items: center;
  width: 190px; /* Adjust width as needed */
  height: auto; /* Adjust height as needed */
}

.level-tabs {
  display: flex;
  flex-direction: column;
  /* Prevents line breaks within the level tabs */
  max-width: 100%;
  transform-origin: left bottom;
  height: 100%;
  justify-content: center; /* Center the rotated tabs vertically */
}

.level-tab {
  padding: 10px 10px;
  margin: 13px ;
  cursor: pointer;
  transition: all 0.3s ease;
 /* Adjust this value for the desired gap between tabs */
  font-size: 14px; /* Adjusted for smaller font size */
  text-align: center;
  transform: rotate(-90deg);
}

.level-tab.active {
  background-color: #4272B8;
  color: white; /* Change text color to white when active */
}

.bottom-line {
  margin-left: 67px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px; /* Adjusted width for vertical line */
  height: 100%;
  background-color: #4272B8;
}


.bottom-lines {
  margin-left: 64px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px; /* Adjusted width for vertical line */
  height: 100%;
  background-color: #4272B8;
}

.content {
  flex-grow: 1;
}

.chapter-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px; /* Added for spacing below chapter sections */
}

.left-chapters,
.right-chapters {
  width: 50%; /* Set equal width for left and right chapters */
}

.left-chapters {
  padding-right: 10px; /* Add gap to the right of left chapters */
}

.right-chapters {
  padding-left: 10px; /* Add gap to the left of right chapters */
}

.chapter {
  margin: 5px 0;
  font-size: 14px; /* Adjusted for smaller font size */
}

.title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px; /* Added for spacing below title */
  margin-top: 8%;
}

.duration {
  font-size: 16px;
  margin-bottom: 20px; /* Added for spacing below duration */
}

.level-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px; /* Added for spacing below level title */
}

.price {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px; /* Added for spacing above price */
  color: #4272B8;
}

@media (max-width: 780px) {
 .mpad{
  padding: 20px;
 }

  .container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    padding-left: 0%;
    max-width: 100%;
  }




  .level-tabs-container {
    position: relative;
    margin-right: 2px;
    display: flex;
    align-items: center;
    width: 60%; /* Adjust width as needed */
    height: auto; /* Adjust height as needed */
    padding: 0%;
  }

  .level-tab{
    transform: rotate(0deg);
    padding: 5px 5px;
    margin: 13px ;
    cursor: pointer;
    transition: all 0.3s ease;
   /* Adjust this value for the desired gap between tabs */
    font-size: 14px;
  }

  .chapter-section {
    flex-direction: column;
    margin-left: 0%;
}

.left-chapters,
.right-chapters {
    width: 100%;
    padding: 0;

}
.chapter{
  font-size: 14px;
}





}




.item-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.item-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.profile-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.item-info {
  flex-grow: 1;
}

.item-info h4 {
  margin: 0;
  font-size: 18px;
}

.item-info p {
  margin: 0;
  color: #888;
  font-size: 12.5px;
  
}

.item-stats {
  display: flex;
  align-items: center;
  font-size: smaller;
}

.rating, .people, .price {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.price{
  margin-top: 0%;
}

.star-img, .people-img, .rupee-img {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.projects{
  max-width: 65%;
  margin-top: 5%;
}
.projects h2{
  font-size: 30px;
  margin-bottom: 4%;
  font-weight: 600;
}

@media (max-width: 780px) {
  
  .projects{
    max-width: 100%;
    margin-top: 0%;
  }

  .item-row {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .profile-img {
    margin-right: 15px; /* Adjust spacing if needed */
  }

  .item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .item-info {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .item-stats {
    font-size: 12px;
    margin-top: 0px;
    padding-top: 0%;
  }

}